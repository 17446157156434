import {
    PanelHeader as VKPanelHeader,
    PanelHeaderBack,
    PanelHeaderContent,
    Title,
    Avatar,
} from '@vkontakte/vkui';

import logoAzot from './images/logo_azot.png';

interface Props {
    onGoBack?: () => void;
    title?: string;
}

export const PanelHeader = ({ title = '', onGoBack }: Props) => {
    return (
        <VKPanelHeader
            before={onGoBack ? <PanelHeaderBack onClick={onGoBack} /> : null}
            separator={false}
        >
            {onGoBack ? (
                <PanelHeaderContent
                    before={<Avatar src={logoAzot} size={36} />}
                >
                    <Title level="2">{title}</Title>
                </PanelHeaderContent>
            ) : (
                <Avatar src={logoAzot} size={44} />
            )}
        </VKPanelHeader>
    );
};
