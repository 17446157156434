import { ChangeEvent } from 'react';

import { FixedLayout, FormItem, Select } from '@vkontakte/vkui';
import { observer } from 'mobx-react';

import { dashboardsStore } from '~/pages/Dashboards/dashboardsStore';

export const CompanyChoice = observer(() => {
    const { companyId, companyOptions, setCompany } = dashboardsStore;

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
        setCompany(event.target.value);
    };

    return (
        <FixedLayout vertical="top" filled>
            <FormItem>
                <Select
                    value={companyId}
                    options={companyOptions}
                    onChange={onChange}
                    forceDropdownPortal={false}
                />
            </FormItem>
        </FixedLayout>
    );
});
