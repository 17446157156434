const X_USER_ID = 'X-User-Id';
const X_PROVIDER_ID = 'X-Provider-Id';

const changeApiPrefix = (url: string) => {
    return url.replace('/api', '/api-app');
};

const setLocalAuthHeaders = function (this: XMLHttpRequest) {
    // добавляем заголовки только при локальной разработке, так как в продакшене vk teams сам будет подставлять
    // x-user-id
    if (
        !process.env.REACT_APP_X_USER_ID ||
        !process.env.REACT_APP_X_PROVIDER_ID
    ) {
        throw new Error('Не указаны параметры авторизации в env.local файле');
    }

    this.setRequestHeader(X_USER_ID, process.env.REACT_APP_X_USER_ID);
    this.setRequestHeader(X_PROVIDER_ID, process.env.REACT_APP_X_PROVIDER_ID);
};

// eslint-disable-next-line func-names
export const xhrInterceptor = function () {
    const { open } = XMLHttpRequest.prototype;

    // eslint-disable-next-line func-names
    XMLHttpRequest.prototype.open = function (
        this: XMLHttpRequest,
        method,
        url,
        async,
        username,
        password,
    ) {
        const urlWithChangedApiPrefix = changeApiPrefix(url as string);

        open.call(
            this,
            method,
            urlWithChangedApiPrefix,
            async,
            username,
            password,
        );

        if (process.env.NODE_ENV === 'development') {
            setLocalAuthHeaders.call(this);
        }
    } as typeof XMLHttpRequest.prototype.open;
};
