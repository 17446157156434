import { observer } from 'mobx-react';
import { Panel, Div } from '@vkontakte/vkui';
import { DashboardsApp, dashboardStore } from '@taxmonitor/dashboards-ui';

import { PanelHeader } from '~/components/PanelHeader';
import { CompanyChoice } from '~/components/CompanyChoice';
import { dashboardsStore } from '~/pages/Dashboards/dashboardsStore';

interface Props {
    id: string;
    onGoBack: () => void;
}

const widgetArgName = 'org_unit';

export const DashboardWidgets = observer(({ id, onGoBack }: Props) => {
    const { dashboardName } = dashboardStore;
    const { companyId } = dashboardsStore;

    return (
        <Panel id={id}>
            <PanelHeader title={dashboardName} onGoBack={onGoBack} />
            <CompanyChoice />
            <Div className="panelPaddingWithFixedLayout">
                <DashboardsApp.MobileDashboardWidgets
                    widgetArg={{ name: widgetArgName, value: companyId }}
                />
            </Div>
        </Panel>
    );
});
