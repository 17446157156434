import { useCallback, useEffect, useState } from 'react';

import vkBridge from '@vkontakte/vk-bridge';
import { View } from '@vkontakte/vkui';

import { DashboardsPanels } from '~/pages/Dashboards/types';
import { DashboardsList, DashboardWidgets } from './panels';

interface Props {
    id: string;
}

export const Dashboards = ({ id }: Props) => {
    const [history, setHistory] = useState([DashboardsPanels.DashboardsList]);
    const activePanel = history[history.length - 1];
    const isStartPage = history.length === 1;

    const goToOtherPanel = useCallback((panel: DashboardsPanels) => {
        setHistory((prevHistory) => [...prevHistory, panel]);
    }, []);

    const goBack = useCallback(() => {
        setHistory((prevHistory) => prevHistory.slice(0, -1));
    }, []);

    useEffect(() => {
        vkBridge.send(
            isStartPage
                ? 'VKWebAppEnableSwipeBack'
                : 'VKWebAppDisableSwipeBack',
        );
    }, [isStartPage]);

    return (
        <View
            id={id}
            history={history}
            activePanel={activePanel}
            onSwipeBack={goBack}
        >
            <DashboardsList
                id={DashboardsPanels.DashboardsList}
                onCardClick={goToOtherPanel}
            />
            <DashboardWidgets
                id={DashboardsPanels.DashboardWidgets}
                onGoBack={goBack}
            />
        </View>
    );
};
