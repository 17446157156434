import { action, observable, onBecomeObserved } from 'mobx';
import { actionAsync } from 'mobx-utils';

import { fetchAction } from '@taxmonitor/front-core';

import { CompanyOption, Api } from '~/services/api/api';

class DashboardsStore {
    @observable companyId: string = '';

    @observable companyOptions: CompanyOption[] = [];

    constructor() {
        onBecomeObserved(this, 'companyOptions', () => {
            this.fetchCompanyOptions();
        });
    }

    @action
    setCompany = (companyId: string) => {
        this.companyId = companyId;
    };

    @action
    setCompanyOptions = (options: CompanyOption[]) => {
        this.companyOptions = options;
        this.setCompany(options[0].value);
    };

    @actionAsync
    fetchCompanyOptions = async () =>
        fetchAction<CompanyOption[]>(Api.getCompanies, ({ data }) => {
            this.setCompanyOptions(data);
        });
}

export const dashboardsStore = new DashboardsStore();
