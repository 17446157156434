import { AxiosRequestConfig } from 'axios';

export interface CompanyOption {
    label: string;
    value: string;
}

interface CompaniesResponse {
    data: CompanyOption[];
    status: number;
    statusText: string;
    headers: any;
    config: AxiosRequestConfig;
    request?: any;
}

const testOptions: CompanyOption[] = [
    {
        label: 'Все компании',
        value: 'all',
    },
    {
        label: 'Азот',
        value: 'azot',
    },
    {
        label: 'Аммоний',
        value: 'ammoniy',
    },
];

export class Api {
    static getCompanies = new Promise<CompaniesResponse>((resolve) => {
        setTimeout(
            () =>
                resolve({
                    data: testOptions,
                    status: 200,
                    statusText: 'success',
                    headers: {},
                    config: {},
                }),
            2000,
        );
    });
}
