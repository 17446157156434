import { useEffect } from 'react';

import { createGlobalStyle } from 'styled-components';
import { AppRoot, Root } from '@vkontakte/vkui';
import bridge from '@instant-messengers/vk-teams-bridge';
import { AppContainer, UILoader, Null } from '@taxmonitor/front-core';

import { viewRoutes } from '~/routes';
import { Views } from '~/types';
import { dynamicPalette, baseTheme, cssVKUIVarsString } from '~/utils';

const GlobalStyle = createGlobalStyle`
    .vkui--vkBase--light, 
    .vkui--vkIOS--light, 
    ${cssVKUIVarsString};
`;

export const App = () => {
    useEffect(() => {
        bridge.send('LoadingCompleted', { ok: true }).catch((error: any) => {
            console.error('LoadingCompletedError: ', error);
        });
    }, []);

    return (
        <AppContainer
            AppRouteComponent={Null}
            dynamicPalette={dynamicPalette}
            customTheme={baseTheme}
        >
            <UILoader />
            <GlobalStyle />
            <AppRoot>
                <Root activeView={Views.Dashboards}>
                    {viewRoutes.map((viewRoute) => {
                        const ViewComponent = viewRoute.component;

                        return (
                            <ViewComponent
                                key={viewRoute.id}
                                id={viewRoute.id}
                            />
                        );
                    })}
                </Root>
            </AppRoot>
        </AppContainer>
    );
};
