import { useCallback, useEffect } from 'react';

import { Panel, Div } from '@vkontakte/vkui';
import { DashboardsApp } from '@taxmonitor/dashboards-ui';

import { PanelHeader } from '~/components/PanelHeader';
import { DashboardsPanels } from '~/pages/Dashboards/types';
import { routerStore } from '@taxmonitor/front-core';

interface Props {
    id: string;
    onCardClick: (panel: DashboardsPanels) => void;
}

export const DashboardsList = ({ id, onCardClick }: Props) => {
    const goToDashboardWidgets = useCallback(
        () => onCardClick(DashboardsPanels.DashboardWidgets),
        [onCardClick],
    );

    useEffect(() => {
        routerStore.history.replace('/');
    }, []);

    return (
        <Panel id={id}>
            <PanelHeader />
            <Div className="panelPadding">
                <DashboardsApp.MobileDashboardsList
                    additionalClickOnCard={goToDashboardWidgets}
                />
            </Div>
        </Panel>
    );
};
