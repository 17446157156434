import {
    BaseThemeType,
    DeepPartial,
    getPaletteByMainColor,
} from '@taxmonitor/themes';

import type { ThemeDescription } from '@vkontakte/vkui-tokens/interfaces/general';
import { lightTheme } from '@vkontakte/vkui-tokens/themeDescriptions/base/vk';
import { expandAll } from '@vkontakte/vkui-tokens/build/expandTheme';
import { compileStyles } from '@vkontakte/vkui-tokens/build/compilers/styles/compileStyles';

const primaryColor = '#63AB59';

// кастомная тема для vkui
const customVKUITheme: ThemeDescription = {
    ...lightTheme,
    colors: {
        ...lightTheme.colors,
        // иконки
        colorIconAccent: primaryColor, // например, в дропдауне селекта галочка справа у выбранного опшина
        colorIconAccentThemed: primaryColor, // например, иконка назад в шапке
        // обводка, линии
        colorStrokeAccent: primaryColor, // например, рамка селекта при фокусе
        colorStrokeAccentThemed: primaryColor,
    },
};

const { pixelifyTheme } = expandAll(customVKUITheme);

export const cssVKUIVarsString = compileStyles('css', pixelifyTheme);

// кастомная тема для taxmon
const fontFamily = '-apple-system,system-ui,"Helvetica Neue",Roboto,sans-serif';
const base13regular = {
    fontFamily,
    fontSize: 13,
    lineHeight: 16,
    fontWeight: 'normal',
    letterSpacing: 0,
};

export const dynamicPalette = getPaletteByMainColor(primaryColor);

export const baseTheme: DeepPartial<BaseThemeType> = {
    fontFamily,
    border: {
        default: {
            width: '0.5px',
            style: 'solid',
            color: 'rgba(0, 0, 0, 0.12)',
        },
    },
    borderRadius: {
        default: '10px',
    },
    text: {
        base15medium: {
            fontFamily,
            fontSize: 16,
            lineHeight: 20,
            fontWeight: 600,
            letterSpacing: 0.1,
        },
        base13medium: {
            fontFamily,
            fontSize: 13,
            lineHeight: 16,
            fontWeight: 600,
            letterSpacing: 0.1,
        },
        base13regular,
    },
    controlTextStyles: {
        m: base13regular,
    },
    palette: {
        m6: '#818C99',
        m7: '#B8C1CC',
        m9: '#000000',
    },
};
