import ReactDOM from 'react-dom';

import {
    AdaptivityProvider,
    ConfigProvider,
    WebviewType,
} from '@vkontakte/vkui';

import { xhrInterceptor } from '~/utils/xhrInterceptor';
import { App } from './App';

import '@vkontakte/vkui/dist/vkui.css';
import './global.css';

if (!process.env.REACT_APP_NPM_LINK) {
    // eslint-disable-next-line global-require
    require('@taxmonitor/dashboards-ui/build/static/css/styles.css');
}

xhrInterceptor();

ReactDOM.render(
    <ConfigProvider
        appearance="light"
        webviewType={WebviewType.INTERNAL}
        isWebView
    >
        <AdaptivityProvider hasPointer>
            <App />
        </AdaptivityProvider>
    </ConfigProvider>,
    document.getElementById('miniApp'),
);
